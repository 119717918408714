<template>
  <div>

      <div class="main-container">
          
          <div class="container">
              <div class="row">
                 <div class="col-sm-4">
                   <CadUserForm v-if="!this.$store.getters.getIsAuth"/>
                   <p v-else><strong>  Você já está logado, para deslogar basta dar enter na barra de endereço ou clique no menu administrativo para utilizar recursos do sistema.</strong></p>

                 </div>
                 <div class="col-sm-4">
                   <h3></h3>
                 </div>
                 
                 
                 <div class="col-sm-4">
                     <!---
                     <LoginForm />
                     
                     -->
                     
                     
                 </div>


              </div>

          </div>


          

          <!-- Formulário -->   
      </div>



  </div>
  
</template>

<script>
   
   import CadUserForm from '../components/forms/CadUserForm.vue'
   //import LoginForm from '../components/forms/LoginForm.vue'

export default {
  name: 'Home',
  components:{
    CadUserForm,
    //LoginForm
  },
  mounted(){
    
  }

}
</script>


<style scoped>
 .main-container{
    margin: 50px;
    min-height: 250px;
  }
</style>


