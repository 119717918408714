<template>
    <div>
        <h2>Inscreva-se</h2>
        
        <!---
        
          <Message :msg="msg" v-show="msg"/>

        
        -->
        
                
        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

        
        <form id="login-form" @submit="saveUser($event)">
            
            <!--

            <div class="input-container">
                <label for="tipousuario">Tipo de Usuário</label>
                <select name="tipousuario" id="tipousuario" v-model="tipousuario">
                    <option value="">Escolha tipo de usuário</option>
                    <option value="admin">Admin</option>
                </select>
            </div>

            -->
            
            
            <div class="input-container">
                <label for="celular">Whatsapp</label>
                <input type="text" id="celular" name="celular"  v-model="celular" placeholder="Digite WhatsApp">
            </div>

            <div class="input-container">
                <label for="nomeusuario">Nome do usuário</label>
                <input type="text" id="nomeusuario" name="nomeusuario"  v-model="nomeusuario" placeholder="Nome do usuário">
            </div>

            <div class="input-container">
                <label for="email">E-Mail</label>
                <input type="text" id="email" name="email"  v-model="email" placeholder="email">
            </div>

            <div class="input-container">
                <label for="telefone">Telefone</label>
                <input type="text" id="telefone" name="telefone"  v-model="telefone" placeholder="telefone">
            </div>

            <div class="input-container">
                <label for="senha">Senha</label>
                <input type="password" id="senha" name="senha"  v-model="senha" placeholder="senha">
            </div>

            <div class="input-container">
                <label for="confirmaSenha">Confirma Senha</label>
                <input type="password" id="confirmaSenha" name="confirmaSenha"  v-model="confirmaSenha" placeholder="Confirma Senha">
            </div>

            <div class="input-container">
                <input type="submit" class="submit-btn" value="Enviar" >
            </div>

        </form>
        <h3>Ainda não tem conta? Então se inscreva agora!</h3>
        
    </div>
</template>

<script>
//import Message from '../../components/Message.vue'
import {urlAPI} from '../../config.js'





export default {
   
   name:'CadUserForm', 
   components:{
       //Message,
      
   },
   
   
   data(){
       return{
           nomeapresentacao:null,
           nomeusuario:null,
           email:null,
           telefone:null,
           tipousuario:null,
           senha:null,
           celular:null,
           hashnumber:null,
           nivel:null,
           foto64:null,
           idusuario:null,
           pathfile:null,
           msg:null,
           tipoAlerta:null,
           confirmaSenha:null
       }
   },
   methods:{

       async saveUser(e){
           e.preventDefault()
           
           
           
           
           
           /**
            * 
            * 
            * this para acessar dados do data()
            * 
            * 
            * * */
           
            const data = {
             nomeapresentacao: "Administrador",   
             nomeusuario:  this.nomeusuario,
             email: this.email,
             telefone: this.telefone,
             tipousuario: "ADMIN",
             senha: this.senha,
             celular: this.celular,
             hashnumber:this.hashnumber,
             nivel: 1,
             foto64: this.foto64,
             idusuario: this.idusuario,
             pathfile:  this.pathfile,
             msg: this.msg

           }

           let dataJson = JSON.stringify(data)
           console.log(dataJson)
           
           console.log(urlAPI) 
          
           try {
                const req = await fetch(urlAPI+"/users/insert",{
                    method:"POST",
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (dataJson.indexOf("OK") > 0){

                   this.tipoAlerta = "alert-success"
                   
                   this.msg = "Usuário inserido com sucesso"

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = "Falha ao enviar usuário"
                    
                    if(dataJson.indexOf("idx_email") > 0){
                        this.msg = "Email cadastrado anteriormente"
                    }


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 
                console.log('Error: ',error);
           }   
            
           
          setTimeout(()=>this.msg = "", 3000) 

            /**
            
           const req = await fetch("http://172.107.45.205:9000/users/insert",{
               method:"POST",
               headers: {"content-type":"application/json"},
               body:dataJson 
           }).then(function(response){
               //this.msg = `Usuário salvo com sucesso`
               return response.json()
               console.log(response)
           }).catch(function(error){
               return error
           })
            
            
            ** */


          



            
            
            //console.log(dataJson)
            //console.log(res)
            //console.log(req)

           

           //const res = await req.json()   

           //Mostrar mensagem de retorno


           //limpar campo
           this.nomeusuario = ""
           this.email = ""
           this.telefone = ""
           this.tipousuario = ""
           this.senha = ""
           this.celular = ""
           this.hashnumber = ""
           this.nivel = ""
           this.foto64 = ""
           this.idusuario = ""
           this.pathfile = ""
           this.confirmaSenha = ""

           


           //console.log("salvando usuario", res)
       }

   },
   mounted(){
      
   }   
}
</script>

<style scoped>
     #login-form{
         max-width: 400px;
         margin: 0 auto;
     }

     .input-container{
         display: flex;
         flex-direction: column;
         margin-bottom: 20px;
     }

     label{
         font-weight: bold;
         margin-bottom: 15px;
         color: #FCBA03;
         padding: 5px 10px;
         border-left: 4px solid #FCBA03;
     }

     input, select{
         padding: 5px 10px;
         width: 300px;
     }

     .submit-btn{
         background: #fff;
         color: #FCBA03;
         font-weight: bold;
         border: 2px solid #222;
         padding: 10px;
         font-size: 16px;
         margin: 0 auto;
         cursor: pointer;
         transition: .5s;
     }

     .submit-btn:hover{
         background-color: transparent;
         color: #FCBA03;
     }




</style>